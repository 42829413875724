


































































































































































































































import Bop from "@/models/Bop";
import MinimumFacturable from "@/models/MinimumFacturable";
import MinimumFacturableService from "@/services/MinimumFacturableService";
import { BopModelEnum } from "@/utils/Enums";
import { roundNumber } from "@/utils/helpers";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import ComplementsCoutsSuppAdministrationService from "@/services/ComplementsCoutsSuppAdministrationService";
import CountryService from "@/services/CountryService";
type ComplementaryDataSummary = {
  key: string;
  value: string;
  description: string;
  currencySymbol: string;
};
@Component({})
export default class BopSummaryStep extends Vue {
  private minimumFacturable: number = 0;
  private complementaryData: Array<ComplementaryDataSummary> = [];
  private get canBeEditedAfterRealised(): boolean {
    return (
      this.$route.query.isEdit !== undefined &&
      this.$route.query.isEdit === "true"
    );
  }

  private get bopShort(): String {
    return `${
      this.bop.siteName
    } - ${this.bop.bopModelName?.toString().toLowerCase()} ${
      this.bop.bopNumber ? " # " : ""
    }${this.bop.bopNumber ?? ""}`;
  }

  private get bop(): Bop {
    return this.$store.state.bop;
  }
  private get bopStatus() {
    return this.$store.state.bopStatuses[this.bop.bopStatusKey];
  }
  private get bopTotalOperationPrincipalDiversePoints() {
    return roundNumber(
      this.bop.totalOperationDiversePoints +
        this.bop.totalOperationPrincipalPoints
    );
  }
  private get bopComplementaryData() {
    return this.$store.state.bop.complementaryData;
  }
  private get bopTotalPoints() {
    let total = this.bop.totalPoints;
    if (
      this.canBeEditedAfterRealised ||
      !Bop.readOnlyStatuses.includes(this.bop.bopStatusKey)
    ) {
      const totalOpprincipaleDiverse =
        this.bopTotalOperationPrincipalDiversePoints > this.minimumFacturable
          ? this.bopTotalOperationPrincipalDiversePoints
          : this.minimumFacturable;
      total = totalOpprincipaleDiverse + this.totalOperationSuppPoints;
    }
    return roundNumber(total);
  }
  private get totalOperationSuppPoints(): number {
    const total =
      (this.bop.totalSuppHauteurPoints ?? 0) +
      this.bop.totalOperationSuppHeurePoints +
      this.bop.totalOperationSuppEpiPoints;
    return roundNumber(total);
  }
  private get isElectriciteInstrumentationBop() {
    return this.bop.bopModelKey === BopModelEnum.ElectriciteInstrumentation;
  }
  private get isTuyauterieMetaliqueBop() {
    return this.bop.bopModelKey === BopModelEnum.TuyauterieMetalique;
  }
  private get language(): string {
    return this.$i18n.locale;
  }
  private async getTuyPlastiqueSupplements(): Promise<
    Array<ComplementaryDataSummary>
  > {
    if (!this.isTuyauterieMetaliqueBop) return [];
    const allComplementsCoutsSuppConfigs = (
      await ComplementsCoutsSuppAdministrationService.getComplementsCoutsSuppAdministration(
        this.language
      )
    ).data;
    const filteredConfig = allComplementsCoutsSuppConfigs.find(
      (c) => c.typeTravauxId === this.bop.typeTravaux
    )?.complementsCoutsSuppKeys;
    let complementaryData = [];
    const country = this.bop.siteId
      ? (await CountryService.getCountryBySite(this.bop.siteId)).data
      : null;

    if (filteredConfig) {
      complementaryData = filteredConfig.map((key) => ({
        key,
        value: this.bop.complementaryData
          ? this.bop.complementaryData[key]
          : "",
        description: this.bop.complementaryData
          ? this.bop.complementaryData[`${key}Description`]
          : "",
        currencySymbol: country?.currencySymbol,
      }));
    }

    return complementaryData;
  }

  private get estimationBlocks() {
    return [
      {
        label: this.$t("bopSetup.summary.totalOperationPrincipalPoints"),
        value: this.isMinimumChargeableApplicable
          ? null
          : this.operationPrincipalEstimation,
        style: "primary",
      },
      {
        label: this.$t("bopSetup.summary.totalOperationDiversePoints"),
        value: this.isMinimumChargeableApplicable
          ? null
          : this.operationDiverseEstimation,
        style: "primary",
      },
      // Conditionally include the "minimumFacturable" block
      ...(this.isMinimumChargeableApplicable
        ? [
            {
              label: this.$t("bopSetup.summary.minimumFacturable"),
              value: this.minimumChargeableEstimation,
              style: "primary",
            },
          ]
        : []),
      {
        label: this.$t("bopSetup.summary.totalOperationSuppEpiPoints"),
        value: this.supplementEpiEstimation,
        style: "secondary",
      },
      {
        label: this.$t("bopSetup.summary.totalOperationSuppHeurePoints"),
        value: this.supplementHeureEstimation,
        style: "secondary",
      },
    ];
  }

  private get operationPrincipalEstimation(): number {
    return roundNumber(
      (this.bop.totalOperationPrincipalPoints ?? 0) * (this.bop.pointValue ?? 0)
    );
  }

  private get operationDiverseEstimation(): number {
    return roundNumber(
      (this.bop.totalOperationDiversePoints ?? 0) * (this.bop.pointValue ?? 0)
    );
  }

  private get minimumChargeableEstimation(): number {
    return roundNumber(
      (this.minimumFacturable ?? 0) * (this.bop.pointValue ?? 0)
    );
  }

  private get supplementEpiEstimation(): number {
    return roundNumber(
      (this.bop.totalOperationSuppEpiPoints ?? 0) * (this.bop.pointValue ?? 0)
    );
  }

  private get supplementHeureEstimation(): number {
    return roundNumber(
      (this.bop.totalOperationSuppHeurePoints ?? 0) * (this.bop.pointValue ?? 0)
    );
  }

  private get totalEstimation(): number {
    // Sum up the 'value' fields from the array
    const additionalCosts =
      this.complementaryData?.reduce((sum, item) => {
        return sum + (parseFloat(item.value) || 0); // Ensure value is parsed as a number
      }, 0) ?? 0;

    // Add additionalCosts to the total estimation
    return roundNumber(
      (this.bopTotalPoints ?? 0) * (this.bop.pointValue ?? 0) + additionalCosts
    );
  }
  private get isMinimumChargeableApplicable(): boolean {
    return (
      this.minimumFacturable > this.bopTotalOperationPrincipalDiversePoints
    );
  }
  /**
   * ######
   * Hooks
   * ######
   */
  private created() {
    if (!this.bop.bopModelId || !this.bop.entrepriseId || !this.bop.typeTravaux)
      return;
    this.getTuyPlastiqueSupplements().then((complementaryData) => {
      this.complementaryData = complementaryData;
    });

    MinimumFacturableService.getByEntrepriseBopModelDomainApplication(
      this.bop.bopModelId,
      this.bop.entrepriseId,
      this.bop.typeTravaux
    ).then((response) => {
      const minimFacturable: MinimumFacturable = response.data;
      this.minimumFacturable = minimFacturable.minimumPoints;
    });
  }
  /**
   * ######
   * Watchers
   * ######
   */

  @Watch("bop.bopModelId")
  onBopModelChange() {
    this.getTuyPlastiqueSupplements().then((complementaryData) => {
      this.complementaryData = complementaryData;
    });
  }
  @Watch("bopComplementaryData", { deep: true })
  private onComplementaryDataChange() {
    this.complementaryData = this.complementaryData.map(
      (d: ComplementaryDataSummary) => ({
        ...d,
        value: this.bop.complementaryData
          ? this.bop.complementaryData[d.key]
          : "",
      })
    );
  }
}
